import React from 'react';
import {relativeDate} from "../formatters";
import { useApolloClient } from '@apollo/client';
import {useAccountId,useAllAccounts} from '../account/AccountInfo';
import queryString from 'query-string';

import gql from "graphql-tag";
import {Link,useLocation,useParams} from 'react-router-dom';

import List from '@mui/material/List';

import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import NewDataflowButton from '../dataflow/NewDataflowButton';
import NewJobSelector from '../dataflow/editor/NewJobSelector';
import DataflowMenu from '../dataflow/DataflowMenu';
import useNotifiers from '../Notifiers';
import ToggleButton from '@mui/material/ToggleButton';
import {AdminOnly} from '../../AdminCheck';
import {useChangeURL} from '../inputs/QueryStringInputs';
import DataflowEditor from '../dataflow/editor/DataflowEditor.js';
import {SidebarListHeader} from '../components/SidebarList';
import JobModal from './JobModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight,faTableRows,faTableCells} from '@fortawesome/pro-regular-svg-icons';


// nav.js
import {useNavigate} from 'react-router-dom';

import {ArchiveButton,UnarchiveButton,RetryButton} from './JobButtons';
import JobListSmallCard from './JobListSmallCard';
import JobFilterMenu from './JobFilterMenu';
import FraktureTable from '../FraktureTable';

import FraktureQuery from '../FraktureQuery';

import dayjs from 'util/day.js';

const jobListFragment=`
	_id
	account_id
	label
	date_created
	status
	archived
	last_update {
		ts
		status
	}
	modified_at
	dataflow{
		last_completed
	}
	jobs {
		_id
		label
		submodule
		status
		method
		errors
		bot_location_id
		output ${''/*output is used to calculate records, but may be large, may have to put in data-layer*/}
		bot{
			_id
			path
			label
			definition{
				_id
				metadata {
					logo
				}
			}
		}
	}
`;

const jobListSubscription=gql(`
subscription jobLists($query: JobListQuery) {
	update: job_list_updates(query:$query) {
		_id
		type
		object: job_list {
			${jobListFragment}
		}
	}
}
`);
const ALL_JOBLIST_QUERY=gql(`
query ALL_JOBLIST_QUERY($query: JobListQuery, $cursor: Cursor) {
	page: job_lists(query:$query, limit:300,cursor: $cursor) {
		cursor
		page {
			${jobListFragment}
		}
	}
}`);

const addJobListMutation = gql(`mutation addJobList($account_id:ID!,$job: JobListNewJob!,$pause_first_job:Boolean) {
	job_list_create(account_id:$account_id,job: $job,pause_first_job:$pause_first_job) {
		_id
	}
}`);

const metricsQuery=gql(`
query metricsQuery($query: JobListQuery) {
	job_list_metrics(query:$query) {
		total
		error
		complete
		in_progress
		distinct_dataflow_total
		distinct_dataflow_error
		distinct_dataflow_complete
		distinct_dataflow_in_progress
	}
}`);

export function useMultiaccount(){
	const location=useLocation();
	return location.pathname.indexOf('multiaccount')>=0;
}

function StatusButton(props){
	const {value, title, status} = props;
	if (!status) return alert("Invalid status");

	const {query,setURLParameters}=useChangeURL();
	function click(){
		if (Array.isArray(query.status)) query.status=query.status.join(",");
		let newStatus=(query.status||"").split(",");
		if (newStatus.indexOf(status)<0) newStatus.push(status);
		else newStatus=newStatus.filter(s=>s!==status);
		newStatus=newStatus.filter(Boolean);
		setURLParameters({status:newStatus});
	}

	let checked=(query.status||"").indexOf(status)>=0;
	let border=checked?"2px solid #333":"2px solid white";
	return (
		<ToggleButton onClick={()=>click()} className={`align-top status-${status}`} style={{padding:"5px 10px",border}} value={value}>
			{title} ({value})
		</ToggleButton>
	);
};

function JobListMetrics(props){
	let d=props.metrics;
	return <div>
		<StatusButton status="error" title="Errored" value={d.error} desc={d.distinct_dataflow_error+" dataflows"}/>
		<StatusButton status="in_progress" title="In Progress" value={d.in_progress} desc={d.distinct_dataflow_in_progress+" dataflows"}/>
		<StatusButton status="complete" title="Complete" value={d.complete} desc={d.distinct_dataflow_complete+" dataflows"}/>
	</div>;
}


function JobListSpreadsheet(props){
	const {job_lists,setJobListIds,nav}=props;
	const multiaccount=useMultiaccount();

	let rowData=job_lists.map(d=>{
		if (!d) return false;
		d.id=d._id;
		if (!d.jobs){
			console.error("Invalid job_list:",d);
			d.jobs=[];
		}
		let keyJob=d.jobs.filter(Boolean).filter(j=>j.status!=='complete')[0]||d.jobs.slice(-1)[0];
		if (keyJob){
			d.key_job_id=keyJob._id;
			d.key_bot=keyJob.bot.path.split(".")[1];
			d.key_method=keyJob.method;
			d.records="";
			if (keyJob.output){
				if (keyJob.output.records!==undefined) d.records=keyJob.output.records;
				let meta=Array.isArray(keyJob.output)?keyJob.output.find(j=>j.name==='metadata'):null;
				if (meta && meta.value && meta.value.records!==undefined)d.records=meta.value.records;
				else if (keyJob.output[0] && keyJob.output[0].value && keyJob.output[0].value.records!==undefined)d.records=keyJob.output[0].value.records;
				else{
					let s=JSON.stringify(keyJob.output[0]||"");
					if (s===undefined || s===null){
						d.records="";
					}else{
						d.records=s.slice(0,50);
					}
				}
			}
			d.bot_location_id=keyJob.bot_location_id;
			d.key_status=keyJob.status;
			d.is_auth_error='';
			if (d.status==="error"){
				let error=((keyJob.errors || []).slice(-1)[0] || {stack:"No error message"});
				//d.error_object=error;
				if (error.level==='authentication')d.is_auth_error="Yes";
				if (typeof error=='object') error=JSON.stringify(error);
				error=error.replace(/error:\s*/gi,"").trim();
				try{error=JSON.parse(error);}catch(e){}
				if (error.message){
					if (typeof error.message=='string' && error.message.indexOf("see details below")>=0){
						//don't assign this,
						if (error.error && error.error.sqlMessage) error=error.error.sqlMessage;
						else if (error.error && error.error.message) error=error.error.message;
					}else{
						error=error.message;
					}
				}else if (error.stack) error=error.stack;
				try{
					if (error.indexOf("{")===0) error=JSON.parse(error);
				}catch(e){}


				if (typeof error=='object') error=JSON.stringify(error);

				d.error=error.slice(0,80);
			}
		}
		d.date_created=dayjs(d.date_created).format("MMM D, hh:mm a");
		let dfl=d.dataflow?d.dataflow.last_completed:null;
		let dd=d.last_update?d.last_update.ts:"";

		if (dfl && dd){
			dfl=dayjs(dfl).toISOString();
			dd=dayjs(dd).toISOString();
			if (dfl>dd){
				d.dataflow_completed="Yes";
			}else{
				d.dataflow_completed="No";
			}
		}else{
			d.dataflow_completed="N/A";
		}

		d.display_date=(d.display_date?.getTime)?dayjs(d.display_date).format("MMM D, hh:mm a z"):d.display_date;
		d.className="status-"+d.key_status;
		d.account_label=d.account_id;
		d.parent_id="";
		d.parent_ids="";
		if (d.account){
			d.account_label=d.account.name;
			if (d.account.parent_ids){
				if (d.account.parent_ids.length>0){
					d.parent_id=d.account.parent_ids[0];
					d.parent_ids=d.account.parent_ids.join();
				}
			}
		}
		return d;
	}).filter(Boolean);
	//return JSON.stringify(rowData);
	let account_ids={};
	rowData.forEach(d=>account_ids[d.account_id]=true);
	console.log(rowData.slice(0,10));

	let cols=[];
	if (multiaccount){
		cols=[
			{ title: 'Parent', field: 'parent_id' },
			{ title: 'Account', field: 'account_label' }];
	}

	cols=cols.concat([
		{ title: 'Job List', field: 'label' },
		{ title: 'Date', field: 'display_date' },
		{ title: 'Auth Error', field: 'is_auth_error' },
		{ title: 'Completed Since', field: 'dataflow_completed' },
		{ title: 'Status', field: 'status' },
		//{ title: 'Status', field: 'key_status' },
		{ title: 'Records', field: 'records' },
		{ title: 'Location', field: 'bot_location_id' },
		{ title: 'Bot', field: 'key_bot' },
		{ title: 'Method', field: 'key_method' },
		{ title: 'Error', field: 'error' },
		{ title: 'Account ID', field: 'account_id' },
		{ title: 'Job List Id', field: '_id' }
	]);
	return <FraktureTable
		size="small"
		rows={rowData}
		columns={cols}
		includeSelectBox={true}
		getRowClass={row=>"status-"+row.status}
		onSelectedChange={ids=>setJobListIds(ids)}
		onRowClick={row=>{
			return nav(row._id,row.key_job_id);
		}}
		includeGroupBy={true}
	/>;
}

export function QuickJob(props){
	const {pause_first_job=false,account_id,bot_id,submodule,method,label="Run Job"}=props;
	const {notify,notifyErr} = useNotifiers();
	const client=useApolloClient();
	return <Button onClick={e => {
		client.mutate({mutation:addJobListMutation,variables:{account_id,job: {bot_id,submodule,method},pause_first_job}})
			.then(result =>{notify({message:"Started Job"});},notifyErr);
	}}>{label}</Button>;
}

function JobSelect({account_id,client,onSelect}){
	const {notify,notifyErr} = useNotifiers();
	return <Grid item xs={12} md={12}>
		<NewJobSelector account_id={account_id} addJob={settings => {
			const {bot_id,submodule,method}=settings;
			if(!bot_id || !method) throw new Error('Need at least bot_id and method');
			client.mutate({mutation:addJobListMutation,variables:{account_id,job: {bot_id,submodule,method},pause_first_job:true}})
				.then(result =>{
					notify("Added joblist");
					if (typeof onSelect=='function') onSelect();
				},notifyErr);
		}}/>
	</Grid>;
}

function JobListHistory(props) {
	const client=useApolloClient();
	const {query_variables,account_id}=props;
	const multiaccount=useMultiaccount();

	const [all_job_list_ids]=React.useState();
	const [selected_job_list_ids,setJobListIds]=React.useState();
	const [view,setView]=React.useState(multiaccount?"list":"grid");
	const {accounts,loading,error}=useAllAccounts();
	if (loading) return loading;
	if (error) return error;
	const accountMap=accounts.reduce((a,b)=>{a[b._id]=b;return a;},{});

	let refetchMetrics=null;
	let refetchList=null;

	function toggleView(){
		setView((view==="grid")?"list":"grid");
	}
	function getJobListIds(){
		if (!selected_job_list_ids || selected_job_list_ids.length===0) return all_job_list_ids;
		return selected_job_list_ids;
	}
	function refetch(){
		if (typeof refetchList=='function') refetchList();
		if (typeof refetchMetrics=='function'){refetchMetrics();}
		setJobListIds([]);
	}
	let metricsVars=JSON.parse(JSON.stringify(query_variables));
	delete metricsVars.status;//So, for metrics, we don't filter by status
	let loadIcon=<span>Loading counts ...</span>;

	return (<>
		<div className="app-main-content-header p-2 d-flex align-items-center justify-content-between">
			<h2 className="title mb-3 mb-sm-0">Jobs</h2>
			{multiaccount?<JobFilterMenu expanded={!props.account_id} account_id={account_id}/>:""}
			<FraktureQuery query={metricsQuery} variables={metricsVars} loadIcon={loadIcon}>
				{ metrics => {
					if (!refetchMetrics) refetchMetrics=metrics.refetch;
					return <JobListMetrics metrics={metrics.job_list_metrics} archived={props.archived}/>;
				}}
			</FraktureQuery>
			{props.archived?
				<UnarchiveButton getJobListIds={e=>getJobListIds()} onCompleted={()=>refetch()}/>:
				<ArchiveButton getJobListIds={e=>getJobListIds()} onCompleted={()=>refetch()}/>
			}
			{!props.archived?
				<RetryButton getJobListIds={e=>getJobListIds()} onCompleted={()=>refetch()}/>:""
			}

			<span style={{"justifyContent":"flex-end"}}><Tooltip title="Toggle View">
				{view==="grid"?
					<Button aria-label="List View" onClick={e=>toggleView()}>
						<FontAwesomeIcon icon={faTableRows} fixedWidth/>
					</Button>:
					<Button aria-label="Grid View" onClick={e=>toggleView()} >
						<FontAwesomeIcon icon={faTableCells} fixedWidth/>
					</Button>
				}</Tooltip></span>
			<Tooltip title="Reload"><Button aria-label="Refresh" onClick={(e)=>{refetch();}} >
				<FontAwesomeIcon icon={faRotateRight} fixedWidth/>
			</Button></Tooltip>
		</div>
		<AdminOnly>{(!multiaccount && account_id)?<JobSelect account_id={account_id} client={client} onSelect={()=>{refetch();}}/>:""}</AdminOnly>
		<FraktureQuery query={ALL_JOBLIST_QUERY} variables={query_variables} subscriptionsOLD={jobListSubscription}>
			{ data => {
				if (!refetchList) refetchList=data.refetch;
				if (!data.map){
					data=data.page.page;
				}

				let sortedLists=data.map(_jl=>{
					let jl=Object.assign({},_jl);
					jl.display_date=jl.last_update?jl.last_update.ts:"";
					if (jl.display_date.getTime) jl.display_date=dayjs(jl.display_date).format("MMM D, hh:mm a");
					jl.account=accountMap[jl.account_id];
					return jl;
				});
				if (sortedLists.length===0) return <Card><CardContent><h5>No Job History</h5></CardContent></Card>;
				if(view === 'grid') return <Grid container className="p-3" spacing={3}>
					{sortedLists.map((l,i)=>{
						return <Grid item key={i} xs={12} sm={12} md={multiaccount?3:4} lg={multiaccount?3:4} xl={3}>
							<JobListSmallCard nav={(job_list_id,job_id)=>props.nav(job_list_id,job_id)}
								key={i} joblist={l}/>
						</Grid>;
					})} </Grid>;
				else return <Grid item xl={12} style={{zoom:"0.9"}}>
					<JobListSpreadsheet account_id={account_id} nav={(job_list_id,job_id)=>props.nav(job_list_id,job_id)}
						job_lists={sortedLists}
						setJobListIds={ids=>{setJobListIds(ids);}}/></Grid>;
			} }
		</FraktureQuery>
	</>
	);
};


export default function Jobs(props){
	const location=useLocation();
	const navigate=useNavigate();
	const client=useApolloClient();
	let account_id = useAccountId(props);
	const multiaccount=useMultiaccount();
	const {dataflow_id:param_dataflow_id}=useParams();
	const {hash}=useLocation();
	const {job_list_id,job_id}=queryString.parse(hash.slice(1));
	let showJob=!!(job_list_id|| job_id);
	if (!account_id) return "account_id is required";
	const url_account_id=account_id;

	function nav(jlid,jid){
		let newHash={};
		if (jlid){
			newHash.job_list_id=jlid;
			if (jid) newHash.job_id=jid;
		}
		//this doesn't work with multiaccount
		let link=["app",url_account_id,"job"];
		let url="/"+link.join("/")+location.search+"#"+queryString.stringify(newHash);
		//try just the current path
		url=location.pathname+location.search+"#"+queryString.stringify(newHash);
		navigate(url);
	}

	let query=queryString.parse(location.search);
	let dataflow_id=query.dataflow_id;
	let archived=false;
	if (query.archived==='true') archived=true;

	let variables={
		query:{
			start:query.start?relativeDate(query.start):null,
			end:query.end?relativeDate(query.end):null,
			archived,
			dataflow_id
		}
	};
	let account_ids;
	if(account_id && !multiaccount) {
		account_ids = [account_id];
	} else if(query.account_id){
		account_ids=[query.account_id];
	}else if (query.account_ids){
		account_ids=query.account_ids.split(",");
	}


	if (account_ids){variables.query.account_ids=account_ids;}
	if (Array.isArray(query.status)) query.status=query.status.join(",");
	let statusArray=(query.status||'').split(',').filter(x=>!!x);
	if (query.job_error_match){
		if (statusArray.indexOf("error")===-1) statusArray.push("error");
		variables.query.job_error_match=query.job_error_match;
	}
	if (query.bot_location_match){
		variables.query.bot_location_match=query.bot_location_match;
	}

	if (query.tracking_code){
		variables.query.tracking_code=query.tracking_code;
	}
	if (query.parent_account_id){
		variables.query.parent_account_id=query.parent_account_id;
	}
	if (query.job_method_match){
		variables.query.job_method_match=query.job_method_match;
	}
	if (query.job_bot_match){
		variables.query.job_bot_match=query.job_bot_match;
	}
	if (query.completed_since==="true"){
		variables.query.completed_since=true;
	}
	if (query.error_level){
		variables.query.error_level=query.error_level;
	}

	if (statusArray.length>0) variables.query.status=statusArray;

	return <div className="app-main-content-ltr">
		{showJob?<JobModal job_list_id={job_list_id} job_id={job_id} />:
			(param_dataflow_id?
				<DataflowEditor dataflow_id={param_dataflow_id} account_id={account_id}/>:
				<>
					{!multiaccount?
						<div id="sidebar2">
							<SidebarListHeader
								primary={[<Link key="main" to={"/app/"+account_id+"/job"}>Dataflows</Link>,<NewDataflowButton key="new" account_id={account_id}/>]}
							/>
							<List className="p-0">
								<DataflowMenu fullItem={true}/>
							</List>
						</div>
						:
						""}
					<div className="w-100">
						<JobListHistory key="history" nav={nav} client={client} account_id={account_id} query_variables={variables}/>
					</div>
				</>)
		}
	</div>;
};
